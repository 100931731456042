import React, { useEffect, useState } from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import SmallBanner from "@components/common/SmallBanner";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import Products from "@components/sections/Products";
import dayjs from "dayjs";
import axios from "axios";
import useSWR from "swr";
import ProductsNew from "@components/sections/products2/Products";

const endpoints = {
    products: "/.netlify/functions/products",
};

const Tienda = () => {
    const {
        data: result,
        isLoading,
        isValidating,
    } = useSWR(endpoints.products, axios.get, {
        dedupingInterval: 30 * 1000,
    });

    const [booksState, setBooksState] = useState([]);
    const [packsState, setPacksState] = useState([]);

    useEffect(() => {
        if (result?.data?.products) {
            // Busamos libros
            let products = result?.data?.products.filter(
                x =>
                    x.enabled &&
                    !x.hidden &&
                    x.category?.includes("books") &&
                    !x.category?.includes("packs"),
            );

            products = products.map(p => {
                const { created_at, updated_at, ...rest } = p;
                return {
                    ...rest,
                    created_at: dayjs(created_at).toDate(),
                    updated_at: dayjs(updated_at).toDate(),
                };
            });

            products = products.sort((a, b) => b.stock - a.stock || b.created_at - a.created_at);

            setBooksState(products);

            // Buscamos packs
            products = result?.data?.products.filter(
                x =>
                    x.enabled &&
                    !x.hidden &&
                    x.category?.includes("books") &&
                    x.category?.includes("packs"),
            );

            products = products.map(p => {
                const { created_at, updated_at, ...rest } = p;
                return {
                    ...rest,
                    created_at: dayjs(created_at).toDate(),
                    updated_at: dayjs(updated_at).toDate(),
                };
            });

            products = products.sort((a, b) => b.stock - a.stock || b.created_at - a.created_at);

            setPacksState(products);
        }
    }, [result]);

    return (
        <>
            <Layout>
                <Seo
                    title="Nuestra tienda: Libros"
                    description="Más rápido, en menos tiempo y directo a lo imprescindible. Ahorra tiempo y fortalece tus conocimientos."
                />

                <SmallBanner
                    title="Libros"
                    subtitle="Más rápido, en menos tiempo y directo a lo
                    imprescindible. Ahorra tiempo y fortalece tus
                    conocimientos."
                    filename="bgs/bgBooks4"
                />

                <div className="container lg:px-0 py-20">
                    <Title>Libros disponibles</Title>
                    <Text className="text-justify">
                        Más rápido, en menos tiempo y directo a lo imprescindible. Ahorra tiempo y
                        fortalece tus conocimientos.
                    </Text>

                    <div className="mt-10 space-y-10">
                        {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                            <ProductsNew items={booksState} loading={isLoading || isValidating} />
                        ) : (
                            <Products items={booksState} />
                        )}

                        <div className="mt-10 text-right text-xs italic font-montserrat text-gray-500">
                            * I.V.A. incluido en todos los precios
                        </div>
                    </div>
                </div>

                <div className="container lg:px-0 py-20">
                    <Title>Packs libros</Title>
                    <Text className="text-justify">
                        ¡Ahorra más dinero con nuestros packs! Obtén más por menos y aprovecha esta
                        oportunidad única para alcanzar el éxito en tu oposición.
                    </Text>

                    <div className="mt-10 space-y-10">
                        {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                            <ProductsNew items={packsState} loading={isLoading || isValidating} />
                        ) : (
                            <Products items={packsState} />
                        )}

                        <div className="mt-10 text-right text-xs italic font-montserrat text-gray-500">
                            * I.V.A. incluido en todos los precios
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default Tienda;
